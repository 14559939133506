import React from "react";

import { Title, Text } from "src/components/page";

const Me = () => (
  <>
    <Title>About me</Title>

    <Text>
      I'm a front end developer, game developer hobbyist and I like making
      interactive stuff
    </Text>

    <Text>
      All the stuff I do is focused around making software that's pleasant and
      fun to use. I'll write the code, make what I need for it to look good (2D,
      3D, websites, whatever), and focus the whole thing around the user's
      experience.
    </Text>

    <Text>
      While that is great, there are usually problems. Fortunately, I like
      problems. I like finding solutions, and I like understanding the best one.
      I like to learn from the problems, and to prevent the same problem from
      cropping up again.
    </Text>

    <Text>
      When I make things, I try to get a good understanding of different areas.
      In website development there's UI and then there's more backend focused
      data management, CSS and animations on one end and project configuration.
      In game development there's 3D art and animation and then of course all
      the game logic, and physics code, and sounds, and everything else. It's
      great!
    </Text>
  </>
);

export default Me;
